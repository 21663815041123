@import "reset";
@import "plyr";
@import "photoswipe";
@import "media-queries";
@import "variables";

/* GLOBAL
---------------------------------------------- */
body {
  font-size: var(--font-size);
  font-family: $font;
  color: $text;
  -webkit-font-smoothing: antialiased;
  background: $background;
}
html {
  scroll-behavior: smooth;
}
.uniform__potty {
  position: absolute;
  left: -9999px;
}
.visually-hidden {
  position:absolute !important;
  overflow:hidden;
  width:1px;
  height:1px;
  margin:-1px;
  padding:0;
  border:0;
  clip:rect(0 0 0 0);
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap:normal !important
}
.hidden-on-mobile {
  display: block;
}
.hidden-on-desktop {
  display: none;
}
.center {
  display: flex;
  justify-content: center!important;
  align-items: center!important;
}
.text-align-center {
  text-align: center;
}
.uppercase {
  text-transform: uppercase;
}
h1, h2 {
  font-size: 3rem;
  line-height: 1.1;
  @include for-phone-only {
    font-size: 2.2rem;
  }
}
.side-padding {
  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
}
img {
  width: 100%;
  height: auto;
  display: block;
}
p, .paragraph {
  font-size: 1rem;
}
.italic {
  font-style: italic;
}
.contents {
  padding: var(--top-padding) var(--side-padding);
}
nav.close-container {
  display: inline-block;
}
.close {
  display: inline-block;
  padding: var(--top-padding) var(--side-padding)
}
blockquote {
  footer {
    margin-top: 1rem;
    font-size: .8rem;
    p {
      font-size: .8rem;
    }
  }
}
.underlined-link {
  text-decoration: underline;
}
@supports (position: sticky) {
  .is-sticky {
    position: sticky;
    top: 0;
    z-index: 2;
  }
}
.dot-container {
 width: 5px;
 margin-right: 6px;
 .dot {
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: $black;
 }
}
header {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  @include for-phone-only {
    flex-direction: column-reverse;
  }
  > .menu-button-container {
    background-color: $yellow;
    z-index: 1;
    @include for-tablet-portrait-up {
      border-right: 1px solid $black;
    }
    .menu-open {
      @include for-phone-only {
        background-color: $yellow;
      }
    }
  }
  button {
    font-size: 1rem;
    text-transform: uppercase;
    line-height: inherit;
    align-items: inherit;
    cursor: pointer;
    padding: .5rem calc(var(--side-padding) + .5rem);
    z-index: 2;
    position: relative;
    background-color: $yellow;
    border-bottom: 1px solid $black;
    margin: 0;
    @include for-phone-only() {
      width: 100%;
      padding: .5rem var(--side-padding);
    }
  }
  .menu-button {
    pointer-events: none;

  }
  .list-item-wrapper {
    display: flex;
    min-width: calc(200px + 1rem - calc(var(--side-padding)*2));
  }
  .right-header-container {
    border-bottom: 1px solid $black;
    flex-grow: 1;
    display: flex;
    z-index: 3;
    /* background-color: $white; */
    @include for-phone-only {
      background-color: $white;
    }
    @include for-tablet-portrait-up() {
      justify-content: end;
    }
  }
  .right-header {
    padding-top: .5rem;
    padding-bottom: .5rem;
    display: block;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.dropdown-content {
  visibility:hidden;
  transform: translate(0, -100%);
  position: absolute;
  min-width: 160px;
  background-color: $yellow;
  transition: transform .5s ease-out;
  border-right: 1px solid $black;
  .list-item-wrapper {
    min-width: calc(280px + 1rem - var(--side-padding) * 2);
  }
  .dot {
    opacity: 0;
  }
}
.dropdown-content a {
  padding: .5rem calc(var(--side-padding) + .5rem); 
  border-bottom: 1px solid $black;
 
}

.dropdown-content a:hover .dot {
  animation: dot 2s ease-in-out infinite;
}

@keyframes dot {
  0% {
    opacity: 0
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.show {
  transform: translate(0, 0);
  visibility: initial;
}

/* HOME TEMPLATE
---------------------------------------------- */
.home-template {
  background-color: $yellow;
}
.landing {
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;
  overflow: auto;
  background-color: $yellow;
  @include for-desktop-up {
    height: 100vh;
    flex-direction: row;
  }
}
.landing-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $yellow;
  li a {
    border-bottom: 1px solid $black;
    display: block;
    padding: .7rem var(--side-padding);
    font-size: 6.5vw;
    @include for-desktop-up {
      font-size: 3.5vw;
    }
  }
  li:first-of-type a {
    padding-top: calc(var(--landing-vertical-padding) * .8);
  }
  .landing-quote {
    padding: var(--landing-vertical-padding) var(--side-padding);
  }
}
.landing-right {
  flex-shrink: 0;
  width: 100vw;
  background-color: black;
  .landing-right-video-container {
    position: relative;
    height: 100%;
    width: 100%;
    video {
      object-fit: cover;
      height: 100%;
      width: auto;
      @include for-phone-only {
        width: 100%;
        height: 90vw;
      }
    }
  }
  .landing-heading {
    color: $yellow;
    line-height: 1.1;
    position: absolute;
    z-index: 1;
    width: 80%;
    padding: calc(var(--landing-vertical-padding) * .75) var(--side-padding) calc(var(--landing-vertical-padding) / 2) var(--side-padding);
  }
  .landing-name {
    right: 0;
    text-align: right;
  }
  .landing-title {
    bottom: 0;
  }
  @include for-desktop-up {    
    width: 117.2vh;
    height: 100vh;
    position: sticky;
    top: 0;
  }
}

/* VIDEOS TEMPLATE
---------------------------------------------- */
.peliculas-template {
  background-color: $black;
  .page-content {
    position: relative;
    z-index: 1;
  }
  .dot {
    background-color: $yellow;
  }
  .menu-button-container {
    background-color: transparent;
    color: $yellow;
    @include for-tablet-portrait-up {
      border-right: 1px solid $yellow;
    }
  }
  button {
    background-color: transparent;
    border-bottom: 1px solid $yellow;
  }
  .right-header-container {
    color: yellow;
    border-bottom: 1px solid $yellow;
    @include for-phone-only {
      background: $black
    }
  }
}
.video-stills-group {
  width: 100%;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: start;
  -ms-box-pack: start;
  -o-box-pack: start;
  box-pack: start;
  -webkit-flex-pack: start;
  -moz-flex-pack: start;
  -ms-flex-pack: start;
  -o-flex-pack: start;
  flex-pack: start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  -o-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-box-align: start;
  -o-box-align: start;
  box-align: start;
  -webkit-flex-align: start;
  -moz-flex-align: start;
  -ms-flex-align: start;
  -o-flex-align: start;
  flex-align: start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  -o-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-box-lines: multiple;
  -o-box-lines: multiple;
  box-lines: multiple;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  @include for-tablet-portrait-up {
    padding-right: 0px!important;
  }
  @include for-phone-only() {    
    margin-bottom: 3rem
  }
  .video-still {
    margin-right: var(--side-padding);
    display: block;
    width: calc((100% - 7.9rem)/4 - 1px);
    -webkit-box-flex: 1;
    -ms-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -moz-flex: 0 0 calc((100% - 7.9rem)/4 - 1px);
    -ms-flex: 0 0 calc((100% - 7.9rem)/4 - 1px);
    -o-flex: 0 0 calc((100% - 7.9rem)/4 - 1px);
    flex: 0 0 calc((100% - 7.9rem)/4 - 1px);
    margin-top: 3rem;
    @include for-phone-only() {    
      width: 100%;
      -moz-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      -o-flex: 0 0 100%;
      flex: 0 0 100%;
    }
    .responsive-image {
      overflow: hidden;
      img {
        display: block;
        @include for-tablet-portrait-up {
          transition: transform .4s cubic-bezier(.77,0,.175,1), opacity 1s;
        }
      }
    }
    &.active-track  {
      .video-still-title {
        color: $yellow;
      }
      img {
        @include for-tablet-portrait-up {    
          -webkit-transform: translateY(calc(100% + 3px)) translateZ(0);
          transform: translateY(calc(100% + 3px)) translateZ(0);
        }
      }
    }
    .video-still-title {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
      -webkit-transform-style: flat;
      transform-style: flat;
      -webkit-tap-highlight-color: transparent;
      -webkit-user-drag: none;
      outline: 0;
      position: relative;
      padding-top: 0.8em;
      font-size: 1.3rem;
      color: $white;
      transition: color .4s ease;
    }
  }
}

video.background-video {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 0;
}


/* VIDEO TEMPLATE
---------------------------------------------- */
.hide-cursor * {
  cursor: none!important;
}
.pelicula-template {
  background-color: $black;
  .header-wrapper {
    top: 0;
    position: absolute;
    transition: transform .4s ease-in-out;
    transform: translate(0, 0%);
    z-index: 10;
    transition-delay: 150ms;
    &.hide-header {
      transform: translate(0, -100%); 
    }
    &:hover {
      transform: translate(0, 0%);
    }
    header {
      width: 100vw
    }
    .right-header-container {
      @include for-phone-only {
        background: $black
      }
    }
  }
  .player-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include for-phone-only {
      height: initial;
      margin-top: 90px;
    }
  }
  .page-content {
    position: relative;
    z-index: 1;
  }
  .dot {
    background-color: $yellow;
  }
  .menu-button-container {
    background-color: transparent;
    color: $yellow;
    @include for-tablet-portrait-up {
      border-right: 1px solid $yellow;
    }
  }
  .dropdown-button {
    background-color: transparent;
    border-bottom: 1px solid $yellow;
  }
  .right-header-container {
    color: yellow;
    border-bottom: 1px solid $yellow;
  }
  main {
    position: relative;
    overflow: hidden;
    height: 100vh;
  }
}
.individual-video-title-overlay {
  position: absolute;
  display: flex;
  z-index: 1;
  width: 100vw;
  justify-content: space-between;
  color: $yellow;
  top: 5rem;
  padding: 0 var(--side-padding);
  font-size: 2rem;
  transition: opacity .4s ease-in-out;
  transition-delay: 150ms;
  /* visibility: visible; 
  pointer-events: all; */
  @include for-phone-only {
    font-size: 1.5rem;
  }
  h1 {
    font-size: inherit;
    @include for-phone-only {
      padding-right: 50px;
      font-size: 1.5rem;
    }
  }
  &.hide-header {
    opacity: 0;
    /* visibility: hidden;
    pointer-events: none; */
  }
  .individual-video-short-info {
    @include for-desktop-up {    
      min-width: 380px
    }
    > div:first-of-type {
      margin-bottom: 1rem;
    }
    .info-buttons-container {
      display: flex;
      justify-content: space-between;
    }
  }
  #textPause {
    display: none;
  }
  .info-header {
    display: flex;
    justify-content: space-between;
  }
  .cross {
    margin-left: 1.5rem;
    @include for-phone-only {
      position:absolute;
      top: 0;
      right: var(--side-padding);
    }
    svg {
      height: 1em;
      width: 1em;
    }
  }
  @include for-phone-only {
    position:relative;
    display: block;
    top: 2rem;
  }
}
/*
#videoOverlay:hover {
  opacity: 1!important
} */

.info-container {
  width: 100vw;
  height: 100vh;
  padding: 5rem var(--side-padding);
  background-color: $yellow;
  position: absolute; 
  bottom: -100vh;
  transition: bottom .4s ease-in-out;
  z-index: 99;
  overflow: scroll;
  blockquote {
    @include for-desktop-up {    
    padding-right: 3rem;
    }
  }
  h1, h2, .h2 {
    font-size: 2rem;
    @include for-phone-only {    
      font-size: 1.5rem;
    }
  }
  .margin-bottom {
    margin-bottom: 4rem;
    height: 100px;
    @include for-phone-only {    
      margin-bottom: 2rem;
      height: initial;
    }
  }
  &.layout-grid {
    padding-top: 2rem;
    padding-bottom: 2rem;
    @include for-phone-only {    
      padding-top: 6rem
    }
    @include for-desktop-up {    
      padding-top: 5rem;
      padding-bottom: 3rem;
    }
    .layout-column {
      @include for-phone-only {    
        margin-bottom: 2rem
      }
    }
  }
  &.show-info-container {
    bottom: 0vh;
  }
  #infoHide {
    @include for-phone-only {    
      position: absolute;
      top: 20px;
      right: var(--side-padding)
    }
  }
}
.gallery {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  img {
    height: 50px;
    width: auto;
  }
  a {
    margin-right: .6rem;
    margin-bottom: .4rem;
    border-bottom: .25rem solid transparent;
    &:hover {
      border-bottom: .25rem solid black;
    }
  }
}

/* PLYR */
.plyr__video-wrapper {
  max-height: 100vh;
}
.plyr--video .plyr__control:focus-visible, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: transparent;
}
.plyr--video .plyr__controls {
  background: none;
  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
  justify-content: flex-end;
  color: $yellow;
  transition-delay: 150ms;
}
.plyr--video .plyr__control:focus-visible, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  color: $yellow;
}
.plyr__control--overlaid {
  padding: calc(var(--plyr-control-spacing,10px)*2.5);
}
.plyr__control--overlaid svg {
  width: 50px;
  height: 50px;
}
.plyr__volume {
  align-self: flex-end;
  min-width: initial;
  width: initial;
}
.plyr__time {
  font-size: 1rem;
}

.dropdown-button {
  @include for-phone-only {
    height: 100%;
  }
}
/* TRANSPARENT HEADER OPEN */
.menu-open {
  @include for-tablet-portrait-up {
    border-right: 1px solid $black!important;
  }
  .dropdown-button {
    background-color: $yellow;
    color: $black;
    border-bottom: 1px solid $black!important;
    // margin-bottom: 0px!important;
    .dropdown-button {
      transition: .4s ease;
    }
  }
  .dropdown-content {
    @include for-phone-only {
      min-width: 100%;
      border-right: none;
    }
    .dot-container {
      @include for-phone-only {
        width: 0;
        margin-right: 3px
      }
    }
  }
  .dropdown-content.show a {
    color: $black!important;
  }
  .dot {
    background-color: $black;
  }
}



/* OTHER TEMPLATES
---------------------------------------------- */
.default-template, .contacto-template, .creditos-template {
  main {
    margin-top: 2rem;
    margin-bottom: 2rem;
    @include for-desktop-up {    
      margin-top: 4.5rem;
      margin-bottom: 3rem;
    }
  }
}
.default-template {
  .layout-grid {
    &:not(:first-of-type) h2 {
      @include for-phone-only {    
        margin-top: 2.5rem;
      }
    }
    .block {
      h1, h2 {
        @include for-phone-only {    
          margin-bottom: 2rem;
          font-size: 2.2rem;
        }
      }
    }
  }
}
.layout-grid {
  --columns: 12;
  display: grid;
  gap: var(--side-padding);
  grid-template-columns: repeat(12, 1fr);
  padding: 0 var(--side-padding);
  @include for-phone-only() {
    grid-template-columns: 1fr;
  }
  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
  &.no-side-padding {
    padding-left: 0;
    padding-right: 0;
  }
  .layout-column {
    grid-column: span var(--columns);
  }
  .block {
    &:not(:last-of-type) {
      margin-bottom: var(--side-padding);
    }
    h1, h2 {
      margin-bottom: 4rem;
      @include for-phone-only {    
        margin-bottom: 2rem;
        font-size: 2.2rem;
      }
    }
    a {
      text-decoration: underline;
      color: blue;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .block-type-gallery {
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: calc(var(--side-padding) / 2);
    }
    figcaption {
      margin-top: .5rem
    }
  }
  h3 {
    font-size: 1.2rem;
    text-transform: uppercase;
    text-align: center;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    padding: .3rem 0;
  }
  .block-type-vimeo {
    .banner-artwork-video {
      padding: 56.35% 0 0 0;
      position: relative;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .video-controls .toggle-play {
      cursor: pointer;
    }
    .player-controls {
      padding: 1rem 0;
      display: inline-block;
      align-self: start;
      text-transform: uppercase;
      font-size: .8em;
      font-weight: 600;
      .toggle-play.play {
        cursor: pointer;
        position: relative;
        left: 0;
        display: inline-block;
      }
      .toggle-play.pause {
        cursor: pointer;
        position: relative;
        display: inline-block;
      }
    }
  }
}
.text-columns {
  @include for-desktop-up {    
    column-count: 2;
    column-gap: var(--side-padding);
  }
}
img.lazy {
  opacity: 0;
}
img.lazy:not(.initial) {
  transition: opacity 1s;
}
img.initial,
img.loaded,
img.error {
  opacity: 1;
}

img:not([src]) {
  visibility: hidden;
}

/* CREDITOS TEMPLATE
---------------------------------------------- */
.creditos-template {
  background-color: $yellow;
  .block {
    a {
      color: var(--black);
    }
  }
}
.block-type-logos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &:not(:last-of-type) {
    margin-top: 6rem
  }
  img {
    align-self: center;
    width: 200px;
    height: auto;
    margin-right: 40px;
    margin-bottom: 30px;
    @include for-phone-only() {    
      width: 160px;
      margin-right: 20px;
    }
  }
  &.small-logos {
    margin-top: 6rem;
    img {
      max-width: 100px;
      margin-right: 30px;
      @include for-phone-only() {    
        width: 90px;
        margin-right: 20px;
      }
    }
  }
}

/* CONTACTO TEMPLATE
---------------------------------------------- */
.text-input, input[type=submit] {
  font-size: 1.3rem!important;
  font-weight: 600!important;
  color: $black;
}
.text-input {
  display: block;
  padding-bottom: .2rem;
  border-bottom: 1px solid $black;
  margin-bottom: 1.2rem;
  label {
    display: block;
    padding-bottom: .2rem;
  }
  textarea {
    padding-top: .4rem;
    min-height: 150px;
    background-color: $white;
  }
}
textarea, input[type=text], input[type=submit], input[type=email], input[type=tel] {
  font-size: 1rem;
  font-weight: 400;
  background-color: $white;
}
input[type=submit] {
  margin-top: .8rem;
  width: initial;
  background-color: $white;
  border: 1px solid black;
  padding: 7px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 30px;
  transition: background-color .3s ease-in-out;
  @include for-desktop-up {    
    &:hover {
      background-color: $yellow;
    }
  }
}
.submit-wrapper {
  display: flex;
  align-content: center;
  align-items: center;
  #message {
    margin-left: 1rem;
  }
}
@media screen and (min-width: 800px) {
  /* HOME TEMPLATE
  ---------------------------------------------- */
  .landing {
  }
  .landing-right {
  }
}