/* SCSS VARIABLES
---------------------------------------------- */
$yellow: #fffC5D;
$black: black;
$white: #eceae5;
$background: $white;
$text: $black;
$font: Helvetica Neue, Helvetica, Arial, sans-serif;
$width: 500px;

/* CSS CUSTOM PROPERTIES
---------------------------------------------- */
:root {
  --top-padding: 10px;
  --side-padding: 2rem;
  --landing-vertical-padding: 2rem;
  --vh: 1;
  --font-size: 1rem;
  --plyr-color-main: #fffC5D;
  --plyr-font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  --plyr-badge-border-radius: 1px;
  --plyr-video-controls-background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  --plyr-video-control-color: #fffC5D;
  --plyr-progress-loading-size: ;
  --plyr-progress-loading-background: rgba(35, 40, 47, 0.6);
  --plyr-video-progress-buffered-background: rgba(255, 255, 255, 0.25);
  --plyr-audio-progress-buffered-background: rgba(193, 200, 209, 0.6);
  --plyr-range-thumb-height: 8px;
  --plyr-range-thumb-background: #fffC5D;
  --plyr-range-thumb-shadow: 0px;
  --plyr-range-track-height: 1px;
  --plyr-tooltip-background: #fffC5D;
}

/* MOBILE CSS CUSTOM PROPERTIES
---------------------------------------------- */
@media (max-width: 599px) {
  :root {
    --top-padding: 10px;
    --side-padding: 10px;
    --landing-vertical-padding: 1.2rem;
    --vh: 1;
    --font-size: 1rem;
  }
}

/* PHOTOSWIPE
---------------------------------------------- */
.pswp--custom-icon-colors {
  --pswp-icon-color: #fffC5D;
  --pswp-icon-color-secondary: #fffC5D;
}